import React from "react";
import containerData from './containerData.json';

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-100 bg-fixed">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                 <h2 className="mb-4 fw-7 txt-blue wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                    <span className="fw-6 txt-orange">Teslim Şekilleri</span>
                                </h2>
                                {/* <p className="wow" data-wow-duration="0" data-wow-delay="0.2s">Uluslararasi lojistik ve tasimacilik işlemleri için geçerli olan tüm taşıma türleri için teslim şekilleri denizyolu, karayolu, havayolu taşımacılığı, demiryolu fark etmeksizin uluslararası arenada kullanılır. </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">Geniş kapsam ifade eden yedi taşımacılık şekli şu şekilde sıralanır:</p> */}
                                {/* <ul>
                                    <li>EXW</li>
                                    <li>FCA</li>
                                    <li>CPT</li>
                                    <li>DPU</li>
                                    <li>DAP</li>
                                    <li>DDP</li>
                                </ul> */}
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">EXW – Ex Works</div>
                                    Genellikle herhangi bir maliyet dahil edilmeksizin malların satışı için bir ilk teklif verirken kullanılan EXW, satıcının malları kendi tesisinde veya belirtilen başka bir yerde (iş yeri, fabrika, depo vb.) mevcut kıldığı anlamına gelir. Satıcının malları herhangi bir toplama aracına yüklemesi veya ihracat için malları gümrükten çekmesi gerekmez.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">FCA – Taşıyıcıya Masrafsız Teslim (adlandırılmış teslimat yeri)</div>
                                    FCA, her biri alıcı ve satıcı için farklı risk ve maliyet seviyeleriyle iki farklı anlama sahip olabilir. FCA (a), satıcı ihracat için deklare edilmiş olan malları kendisine ait olan, belirtilen bir yerde teslim ettiğinde kullanılır. FCA (b), satıcı ihracat için deklare edilmiş olan malları kendisine ait olmayan, belirtilen bir yerde teslim ettiğinde kullanılır. Her iki durumda da mallar, alıcı tarafından belirlenen bir taşıyıcıya veya alıcı tarafından belirlenen başka bir tarafa teslim edilebilir.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">CPT – Carriage Paid To (Taşıma Ücreti Ödenmiş Teslim) (adlandırılmış teslimat yeri)</div>
                                    CPT kapsamında satıcı, malların belirtilen varış yerine taşınması için ödeme yapar.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">CIP – Carriage and Insurance Paid to (Taşıma Ücreti ve Sigorta Ödenmiş Teslim) (belirtilen teslimat adresine)</div>
                                    Satıcının, transitteyken mallar için asgari sigorta edinmesini gerektiren istisnayla CPT'ye benzerdir. 
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">DAP – Delivered at Place (Belirlenen Yerde Teslim)</div>
                                    Satıcı, mallar gelen taşıma araçlarında alıcının tasarrufu için sunulup belirtilen varış yerinde boşaltılmaya hazır hale geldiğinde malları teslim etmiş sayılır. Satıcı, DAP şartları kapsamında malların teslim edilmesi ile ilgili olarak tüm riskleri yönetmekle sorumludur.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">DPU – Delivered at Place Unloaded (Belirtilen Yerde Eşya Yüksüz Teslim)</div>
                                    Bu Incoterm, satıcının malları belirtilen yere boşaltılmış olarak teslim etmesini gerektirir. Satıcı, tüm taşıma masraflarını karşılar (ihracat ücretleri, taşıma, malların varış limanında ana taşıyıcıdan boşaltılması ve varış limanı ücretleri) ve varış yerine varıncaya kadar tüm riski üstlenir.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">DDP – Free Alongside Ship (Gümrük Resmi Ödenmiş Teslim) (adlandırılmış teslimat yeri)</div>
                                    Satıcı, malları alıcının ülkesinde belirtilen adrese teslim etmekle sorumludur ve gümrük resimleri ve vergiler dahil malların varış noktasına getirilmesiyle ilgili tüm masrafları öder. Satıcı, malların boşaltılmasından sorumlu değildir.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">FAS – Free Alongside Ship (Gemi Doğrultusunda Masrafsız Teslim) (belirtilen sevkiyat limanında)</div>
                                    Satıcı, malları, alıcı tarafından belirtilen sevkiyat limanında belirlenen geminin yanına (örneğin, bir iskele veya mavna üzerine) yerleştirdiğinde teslimatı yapmış sayılır. Malların kaybolma veya hasar görme riski, mallar geminin yanına yerleştirildiğinde alıcıya geçer ve tüm masrafların sorumluluğunu o andan itibaren alıcı üstlenir.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">FOB – Free on Board (Gemide Masrafsız Teslim)</div>
                                    Satıcı, malları, belirtilen sevkiyat limanında alıcı tarafından belirlenen gemiye teslim eder veya halihazırda teslim edilmiş malları tedarik etmiş sayılır. Malların kaybolma veya hasar görme riski, mallar gemiye yüklendiğinde alıcıya geçer ve tüm masrafların sorumluluğunu o andan itibaren alıcı üstlenir.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">CFR – Cost and Freight (Maliyet ve Nakliye)</div>
                                    Satıcı malları gemiye teslim eder. Malların kaybolma veya hasar görme riski, mallar gemiye yüklendiğinde alıcıya geçer. Satıcı, malları belirtilen varış limanına getirmek için sözleşme yapmak ve gerekli olan nakliye masrafları ve diğer masrafları ödemek zorundadır.
                                </p>
                                <p className="wow" data-wow-duration="0" data-wow-delay="0.4s">
                                    <div className="fw-6 txt-orange">CIF – Maliyet, Sigorta ve Nakliye</div>
                                    Satıcının, taşıma sırasında mallara ilişkin alıcının kayıp veya hasar riskine karşı asgari sigorta teminatı edinmesi gerektiğine dair ekleme CFR ile aynıdır.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 bg-fixed bg-light-gray">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <h2 className="mb-4 fw-7 txt-blue wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                    <span className="fw-6 txt-orange">Dorse Ölçüleri</span>

                                </h2>
                            </div>
                            <div className="row" style={{ justifyContent: 'space-between', width: '100%' }}>
                                <div className="col-md-4 text-center">
                                    <div style={{ width: '100%' }}>
                                        <div className="bg-orange rounded text-white">MEGA</div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Uzunluk</span>
                                            <span>13,60 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Genişlik</span>
                                            <span>2,48 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Yükseklik</span>
                                            <span>3 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Hacim</span>
                                            <span>102 m^3</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 text-center">
                                    <div style={{ width: '100%' }}>
                                        <div className="bg-orange rounded text-white">OPTIMA</div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Uzunluk</span>
                                            <span>13,60 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Genişlik</span>
                                            <span>2,48 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Yükseklik</span>
                                            <span>2.7 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Hacim</span>
                                            <span>91 m^3</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 text-center">
                                    <div style={{ width: '100%' }}>
                                        <div className="bg-orange rounded text-white">KAMYON ROMORK</div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Uzunluk</span>
                                            <span>2 X 8,00 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Genişlik</span>
                                            <span>2,45 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Yükseklik</span>
                                            <span>3 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Hacim</span>
                                            <span>120 m^3</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 text-center">
                                    <div style={{ width: '100%' }}>
                                        <div className="bg-orange rounded text-white">JUMBO</div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Uzunluk</span>
                                            <span>13,60 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Genişlik</span>
                                            <span>2,48 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Yükseklik</span>
                                            <span>2,65 m 1-2 Cover / 3,00m  2-4 Cover</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Hacim</span>
                                            <span>98 m^3</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 text-center">
                                    <div style={{ width: '100%' }}>
                                        <div className="bg-orange rounded text-white">FRIGO TERMOKINLI</div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Uzunluk</span>
                                            <span>13,60 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Genişlik</span>
                                            <span>2,40 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Yükseklik</span>
                                            <span>2,60 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Hacim</span>
                                            <span>96 m^3</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 text-center">
                                    <div style={{ width: '100%' }}>
                                        <div className="bg-orange rounded text-white">JUMBO AÇIK</div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Uzunluk</span>
                                            <span>13,60 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Genişlik</span>
                                            <span>2,48 m</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Yükseklik</span>
                                            <span>-</span>
                                        </div>
                                        <div className="row" style={{ justifyContent: 'space-between', margin: '10px 10px' }}>
                                            <span style={{ fontWeight: 600 }} className="text-bold">Hacim</span>
                                            <span>-</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="wide-tb-100 bg-fixed">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <h2 className="mb-4 fw-7 txt-blue wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                    <span className="fw-6 txt-orange">Konteyner Ölçüleri</span>
                                </h2>
                            </div>
                            <div className="row" style={{ justifyContent: 'space-between', width: '100%' }}>
                                <div className="col-md-4 text-center">
                                    <p>20 Dry Cargo</p>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>İç Ölçüleri</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>19 ft 4.17 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>Kapı Açılışı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,336mm</td>
                                                <td>7 ft 8.01 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,280mm</td>
                                                <td>7 ft 5.45 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>Ağırlık</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Max Brüt</td>
                                                <td>30,480kg (24,000kg)</td>
                                                <td>67,20lb (52,910lb)</td>
                                            </tr>
                                            <tr>
                                                <td>Dara</td>
                                                <td>2,240kg (2,290kg)</td>
                                                <td>4,940lb (5,050lb)</td>
                                            </tr>
                                            <tr>
                                                <td>Max Yükleme</td>
                                                <td>28,240kg (21,710kg)</td>
                                                <td>62,260lb (47,860lb)</td>
                                            </tr>
                                            <tr>
                                                <td>Yükleme Hacmi</td>
                                                <td>33 metre küp</td>
                                                <td>1,165 feet küp</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 text-center">
                                    <p>40 Dry Cargo</p>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>İç Ölçüleri</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>18 ft 4.18 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>Kapı Açılışı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>18 ft 4.18 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>Ağırlık</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>18 ft 4.18 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 text-center">
                                    <p>40 Dry Cargo</p>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>İç Ölçüleri</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>18 ft 4.18 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>Kapı Açılışı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>18 ft 4.18 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <table style={{ width: '100%' }} border="1">
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>Ağırlık</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Uzunluk</td>
                                                <td>5,897mm</td>
                                                <td>18 ft 4.18 in</td>
                                            </tr>
                                            <tr>
                                                <td>Genişlik</td>
                                                <td>2,348mm</td>
                                                <td>7 ft 8.44 in</td>
                                            </tr>
                                            <tr>
                                                <td>Yükseklik</td>
                                                <td>2,390mm</td>
                                                <td>7 ft 9.90 in</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
export default Content;
