import React from "react";

const Services = () => {
    return (
        <>
            <main id="body-content">
                <section className="bg-white wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">Ana Hizmetlerimiz</h1>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s">
                                <a href="/servicedetails#road">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-1.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-vehicle-delivery-van"></i>
                                            KARAYOLU TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                <a href="/servicedetails#air">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-2.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-airplane-alt"></i>
                                            HAVAYOLU TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#sea">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-3.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-ship"></i>
                                            DENİZYOLU TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#fair">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-4.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-cube"></i>
                                            FUAR LOJİSTIĞİ
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#adr">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-5.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-warning"></i>
                                            TEHLİKELİ MADDE TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#combined">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-6.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-package"></i>
                                            KOMBİNE TAŞIMACILIK
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#frigofic">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-1.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-snow"></i>
                                            FRİGOFİK TAŞIMACILIK
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#warehouse">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-8.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-gift"></i>
                                            DEPOLAMA HİZMETLERİ
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#insurance">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-9.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-ssl-security"></i>
                                            SİGORTA HİZMETİ
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails#customs">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-10.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-certificate"></i>
                                            GÜMRÜKLEME HİZMETLERİ
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-sky-blue wide-tb-100">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="img-business-man">
                                <img src="images/courier-man.png" alt="" />
                            </div>
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">
                                    Öne Çıkan Özelliklerimiz
                                </h1>
                            </div>
                            <div className="col-md-6 ml-auto">
                                <div className="row">
                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-box"></i>
                                                </div>
                                                <div className="service-inner-content">
                                                    <h4 className="h4-md">Paketleme and Depolama</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.2s">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-shield"></i>
                                                </div>
                                                <div className="service-inner-content">
                                                    <h4 className="h4-md">Güvenlik & Kalite</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                        <div className="icon-box-3 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-tree-alt"></i>
                                                </div>
                                                <div className="service-inner-content">
                                                    <h4 className="h4-md">Doğaya Saygılı Taşımacılık</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-light-gray">
                    <div className="container-fluid p-0">
                        <div className="row align-items-center no-gutters">
                            <div className="col-lg-4 text-center">
                                <div className="px-5 wide-tb-100">
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-glass"></i>
                                    </div>
                                    <h4 className="h4-md fw-7 txt-blue">KALİTELİ & GÜVENLİ</h4>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center bg-fixed clients-bg pos-rel txt-white">
                                <div className="bg-overlay black opacity-40"></div>
                                <div className="px-5 wide-tb-100" style={{ position: "relative", zIndex: "999" }}>
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-delivery-time"></i>
                                    </div>
                                    <h4 className="h4-md fw-7">HIZLI TAŞIMACILIK</h4>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="px-5 wide-tb-100">
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-live-support"></i>
                                    </div>
                                    <h4 className="h4-md fw-7 txt-blue">7/24 DESTEK</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
export default Services;
