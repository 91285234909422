import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./home";
import Informations from "./informations";
import NotFound from "./pages/notfound";
import Services from "./services/services";
import RoadService from "./services/servicedetails/road";
import ServiceDetails from "./services/servicedetails/index";
import SeaService from "./services/servicedetails/sea";
import AirService from "./services/servicedetails/air";
import FairService from "./services/servicedetails/fair";
import AdrService from "./services/servicedetails/adr";
import InsuranceService from "./services/servicedetails/insurance";
import About from "./about";
import Contact from "./contact/contactus";

function MainNavigation() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/notfound" exact={true} element={<NotFound />} />
        <Route path="/about" exact={true} element={<About />} />
        <Route path="/services" exact={true} element={<Services />} />
        <Route path="/informations" exact={true} element={<Informations />} />
        <Route path="/servicedetails" exact={true} element={<ServiceDetails />} />
        <Route path="/services/road" exact={true} element={<RoadService />} />
        <Route path="/services/air" exact={true} element={<AirService />} />
        <Route path="/services/sea" exact={true} element={<SeaService />} />
        <Route path="/services/fair" exact={true} element={<FairService />} />
        <Route path="/services/adr" exact={true} element={<AdrService />} />
        <Route path="/services/insurance" exact={true} element={<InsuranceService />} />
        <Route path="/contact" exact={true} element={<Contact />} />
        <Route element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default MainNavigation;
