import React from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="bg-white wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">Hizmetlerimiz</h1>
                            </div>

                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.1s">
                                <a href="/servicedetails">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-1.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-vehicle-delivery-van"></i>
                                            KARAYOLU TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.4s">
                                <a href="/servicedetails">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-2.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-airplane-alt"></i>
                                            HAVAYOLU TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-4 wow fadeInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <a href="/servicedetails">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-3.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-ship"></i>
                                            DENİZYOLU TAŞIMACILIĞI
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-12 text-center">
                                <div className="spacer-50">
                                </div>
                                <a href="/services" className="btn-theme bg-orange">TÜM HİZMETLERİMİZ<i className="icofont-rounded-right"></i></a>
                                <div className="spacer-30">
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="bg-light-gray wide-tb-100 bg-wave">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="img-business-man">
                                <img src="images/business-man.png" alt="" />
                            </div>
                            <div className="col-sm-12 wow fadeInDown" data-wow-duration="0" data-wow-delay="0s">
                                <h1 className="heading-main">Bizi Özel Kılan Nedir?</h1>
                            </div>
                            <div className="col-md-8 ml-auto why-choose wow fadeInRight" data-wow-duration="0" data-wow-delay="0.6s">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-id"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Butik Hizmet Anlayışı</h4>
                                                    <p>Müşterilerimizin ihtiyaçlarına uygun çözümler üreterek, yüksek kaliteli hizmet ile uzun soluklu güçlü müşteri ilişkileri kurup, çözüm ortaklıkları oluşturmaktayız.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-live-support"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">7/24 Destek</h4>
                                                    <p>Tecrübeli uzman personel ile günün her anında ulaşılabilir hizmet sağlamaktayız.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 spacer-60 d-none d-md-none d-lg-block d-sm-none"></div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-history"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Sürdürülerbilirlik</h4>
                                                    <p>Lojistik faaliyetlerinde rota optimizasyonu yapabilen ve karbon ayak izinin hesaplanmasına olanak tanıyan yazılımların kullanılmaktadır.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="icon-box-2">
                                            <div className="media">
                                                <div className="service-icon">
                                                    <i className="icofont-briefcase-1"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Yeşil Lojistik</h4>
                                                    <p>Gelecek nesillere yaşanabilir bir dünya bırakmak için yeşil lojistik uygulamaları için politikalar belirlemiştir.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="wide-tb-100 bg-scroll counter-bg pos-rel">
                    <div className="bg-overlay blue opacity-50"></div>
                    <div className="container">
                        <div className="row" style={{ justifyContent: 'space-between'}}>
                            {/* <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <p><i className="icofont-google-map"></i></p>
                                <span className="counter">2</span>
                                <div>Lokasyonlarımız</div>
                            </div> */}

                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <p><i className="icofont-globe"></i></p>
                                <span className="counter">250</span>
                                <span>+</span>
                                <div>Global Müşterilerimiz</div>
                            </div>

                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>

                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0">
                                <p><i className="icofont-vehicle-delivery-van"></i></p>
                                <span className="counter">72</span>
                                {/* <span>+</span> */}
                                <div>Araçlarımız</div>
                            </div>

                            <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <p><i className="icofont-umbrella-alt"></i></p>
                                <span className="counter">1</span>
                                <span>M+</span>
                                <div>Taşınan Kapasite(T)</div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
export default Content;
