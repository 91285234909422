import React from "react";

const ServiceDetails = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <p>Uluslararası ticaretin kıtalararası en önemli sevk aracı olan deniz taşımacılığında, müşterilerimizin taleplerine uygun hizmet sunmaktayız.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h3 className="h3-md fw-7 mb-4 txt-blue">Sunduğumuz bazı hizmetler</h3>
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li><i className="icofont-check"></i>On taşıma, vgm, fabrika dolum lokal, varıs lokal, son tasıma dahil. iki taraf gümrükleme ve sigorta harci</li>
                                        <li><i className="icofont-check"></i>Gümrüksel veya operasyonel süreçler</li>
                                        <li><i className="icofont-check"></i>Kapıdan kapıya hizmet</li>
                                        <li><i className="icofont-check"></i>Dünyanın her yerinden her yerine deniz nakliye bağlantılı multimodal çözümler</li>
                                        <li><i className="icofont-check"></i>Dünya genelinde proje taşımacılığı</li>
                                        <li><i className="icofont-check"></i>Çıkış varış ihbarlarının müşterilere email ile otomatik bildirimi</li>
                                        <li><i className="icofont-check"></i>Uluslararası acente ağı</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <img src="images/blog_img_4.jpg" alt="" className="rounded mb-4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    );
};
export default ServiceDetails;
