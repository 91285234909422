import React from "react";

const ServiceDetails = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <p>Uluslararasi ticaretin ve global ekonominin en önemli unsurlarindan fuarlar, ozel sergiler, kongreler, toplantılar için deneyimli personeli ile hizmet saglamaktayiz.</p>
                                            <p>Malzemelerin çıkış işlemleri için dağıtım noktasına getirilmesini
                                                Geçici ya da kati ihracat için gerekli gümrük işlemlerinin yapılması ni
                                                Fuar malzemelerinin dünyanın herhangi bir noktasına kara, deniz, hava, demiryolu kombine taşıma yöntemleri ile en optimum şekilde nakledilmesini
                                                Varış ülkesinde geçici ve kati gümrükleme işlemlerinin yapılmasıni
                                                Malzemelerin fuar öncesi varış ülkesinde depolanması ve belirtilen tarihte teslimatı
                                                Fuar alanında gerekli iş gücü ve teknik ekipman sağlamaktayiz
                                                Fuar sonrası malzemelerin stanttan alınması, ıthalat gümrük işlemlerinin yapılmasıni
                                                En uygun dönüş nakliyesinin organize edilerek yurda giriş gümrük işlemlerinin yapılması saglamaktayiz.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    );
};
export default ServiceDetails;
