import React from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-12 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">Hakkımızda</h2>
                                <p>TOKER Lojistik 2005 yılında  uluslararasi kara, hava ve denizyolu taşımacılığı, proje taşımacılığı  ve fuar lojistiği   için entegre hizmetler sağlamak ve aynı zamanda batı ile doğu arasında bir köprü oluşturmak amacı ile operasyonel faliyetlerine Bulgaristan’da başlamıştır.</p>
                                <p>Sonrasında Türkiye’deki müşterilerine daha iyi hizmet sunabilmek için Istanbul’a bir şube açmıştır. Şirketimizin odak noktası, Turkiye’den Avrupa’ya ve Avrupa’dan Türkiye’ye Lojistik faaliyetleri gerçekleştirmektedir. Bu güzergahlarda kapıdan kapıya hizmet vermektedir.</p>
                                <p>Avrupa’nın bir çok noktasındaki acenta ağı sayesinde, gerek gümrük gerek toplama ve dağıtım hizmetleri ile müşterilerinin kapıdan kapıya taşıma taleplerini karşılamaktadır.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light-gray wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">Vizyon</h2>
                                <p>Gelişen teknolojileri takip ederek, gelişime yatırım yaparak, müşterilerine katma değerli hizmetler sunan, iş modellerini geliştiren,  müşterilerle bağlarını sürekli geliştiren sunduğu entegre çözümlerle tanınırlığı olan prestijli bir marka yaratmak.</p>
                            </div>
                            <div className="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/map-bg-orange.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">Misyon</h2>
                                <p>Müşteri ihtiyaçlarına odaklı, özel çözümler sunabilen, çalışan ve müşteri memnuniyetini önemseyen, hizmette kalite standartlarına uyumlu, verimlilik esaslarını gözeten,larına maliyet odaklılık ve sürdürülebilir karlılık anlayışı tüm paydaşlarına fayda sağlayan, müşterilerinin rekabet güçlerini arttırmayı ilke edinen, şeffaf,güvenilir,  stratejik bir çözüm ortağı olan ve  marka değeri kuvvetli lokal bir lojistik firması olmak.</p>
                            </div>
                            <div className="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/map-bg-orange.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light-gray wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">Değerlerimiz</h2>
                                <ul>
                                    <li>Müşterilerimizin memnuniyetini esas tutmak</li>
                                    <li>Uzun soluklu güçlü müşteri ilişkileri kurmak, çözüm ortaklıkları oluşturmak</li>
                                    <li>Müşterilerimizin ihtiyaçlarına uygun çözümler üretmek, yüksek kaliteli  hizmet sunmak</li>
                                    <li>Etik ilkeleri benimsemek</li>
                                    <li>Tecrübeli uzman personel ile çalışmak</li>
                                    <li>Sistemli süreçler oluşturarak malların zarar görmeden istenen yere ulaşmasını sağlamak, olumsuz durum ve riskleri belirlemek çözümler bulmak </li>
                                </ul>
                            </div>
                            <div className="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/map-bg-orange.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">Sürdülebilirlik</h2>
                                <ul>
                                    <li>Lojistik faaliyetlerinin daha verimli ve çevre dostu taşıtlarla gerçekleştirilmektedir.</li>
                                    <li>Lojistik faaliyetlerinde rota optimizasyonu yapabilen ve karbon ayak izinin hesaplanmasına olanak tanıyan yazılımların kullanılmaktadır.</li>
                                    <li>Lojistik süreçlerde farklı taşıma modlarını kullanarak multimodal taşımacılık faaliyetlerinden faydalanarak verimliliğin artırılması ve yeşil lojistiğin desteklenmektedir.</li>
                                </ul>
                            </div>
                            <div className="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/map-bg-orange.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light-gray  wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <h2 className="mb-4 fw-7 txt-blue">Yeşil Lojistik</h2>
                                <p>TOKER Lojistik gelecek nesillere yaşanabilir bir dünya bırakmak için yeşil lojistik uygulamaları için politikalar belirlemiştir. </p>
                                <ul>
                                    <li>Ürün üretimi ve dağıtımın ekolojik sisteme zarar vermeden yapmaktadır.</li>
                                    <li>Çevresel ve sosyal faktörler daima göz önünde bulundurmaktadır.</li>
                                    <li>Gereken analizler yapılarak, ürünler en uygun planlamayla gruplandırılarak taşınır.</li>
                                    <li>Otoritelerin belirlediği çevre dostu olarak tanımlanan motorlu araçlar ve yakıtlar tercih etmektedir. (EURO 5’ e sahip araçların kullanılması vb.).</li>
                                    <li>Depolama ve tesislerde yenilenebilir enerji kullanmaktadır.</li>
                                    <li>Paketleme ve sevkiyatta minimum seviyede ambalaj malzemesi kullanımı sağlamaktadir. </li>
                                    <li>Paket ve ambalajlarda geri dönüşümlü alternatif malzemeler tercih etmektedir.</li>
                                    <li>Geri dönüşüm faaliyeti teşvik edilir ve ulaşım modelleri arasında esneklik planlamaktadır.</li>
                                </ul>
                            </div>
                            <div className="col-md-6 wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <img src="images/map-bg-orange.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-50 ">
                    <div className="container wide-tb-100 pb-0">
                        <div className="row d-flex align-items-center">
                            <div className="col col-12 col-lg-4 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0.3s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-globe"></i></p>
                                    <span className="counter">250</span>
                                    <span>+</span>
                                    <div>
                                        Global Müşterilerimiz
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
                            <div className="col col-12 col-lg-4 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0.6s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-vehicle-delivery-van"></i></p>
                                    <span className="counter">72</span>
                                    {/* <span>+</span> */}
                                    <div>
                                        Araçlarımız
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-lg-4 col-sm-6 wow slideInUp" data-wow-duration="0" data-wow-delay="0.9s">
                                <div className="counter-style-1 light-bg">
                                    <p className="mb-1"><i className="icofont-umbrella-alt"></i></p>
                                    <span className="counter">1</span>
                                    <span>M+</span>
                                    <div>
                                        Taşınan Kapasite(T)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-white wide-tb-100 mb-spacer-md">
                    <div className="container">
                        <div className="col-sm-12">
                            <h1 className="heading-main">Bizimle İletişime Geçin</h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 wow fadeInRight" data-wow-duration="0" data-wow-delay="0.2s">
                                <div className="align-self-stretch h-100 align-items-center d-flex bg-with-text">
                                İster taşıma, ister paketleme, tanımlı nakliyeye veya eksiksiz bir tedarik zinciri çözümüne ihtiyacınız olsun, biz sizin için buradayız.
                                </div>
                            </div>
                            <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
                            <div className="col-lg-8 wow fadeInLeft" data-wow-duration="0" data-wow-delay="0.2s">
                                <form action="#" method="post" noValidate="novalidate" className="rounded-field gray-field">
                                    <div className="form-row mb-4">
                                        <div className="col">
                                            <input type="text" name="name" className="form-control" placeholder="Ad Soyad" />
                                        </div>
                                        <div className="col">
                                            <input type="text" name="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="form-row mb-4">
                                        <div className="col">
                                        <select title="Please choose a package" required="" name="Transport_Package" id="Transport_Package" className="custom-select" aria-required="true" aria-invalid="false">
                                                    <option value="">Ulaştırma Şekli</option>
                                                    <option value="Type 1">Karayolu</option>
                                                    <option value="Type 2">Denizyolu</option>
                                                    <option value="Type 3">Havayolu</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div className="form-row mb-4">
                                        <div className="col">
                                            <textarea rows="7" placeholder="Mesaj" className="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-row text-center">
                                        <button type="submit" className="form-btn mx-auto btn-theme bg-orange">Gönder<i className="icofont-rounded-right"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
export default Content;
