import React from "react";

const ServiceDetails = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">FTL (Komple Tır Yüklemeleri) ve  LTL (Parsiyel Tır Yüklemeleri)</h4>
                                            <p>Müşterilerimize taleplerine yönelik uygun araç tipleri  (tenteli, kapalı kasa, frigo (ısı kontrollü) açık (sal kasa), gabari dışı yükleme)  ile Komple Tır Taşımacılığı hizmetlerini sunmaktayız.</p>
                                            <p>Kuvvetli acenta ağımız ile müşterilerimize taleplerine uygun parsiyel taşımacılık hizmeti de sunmaktayız. </p>
                                        </div>
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">Express Tır Yüklemeleri</h4>
                                            <p>Müşterilerimizin acil teslimat taleplerinde gerek FTL, gerek LTL yüklemlerinizi Express Tır servisi ile transit sürelerde avantaj sağlayarak hizmet sunmaktayız</p>
                                        </div>
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">Express Minivan ve Megavan Yüklemeleri</h4>
                                            <p>Havayolu Taşımacılığına rakip olarak sunulan Minivan servisimiz ile gerek maliyet, gerek transit süre olarak ciddi avantaj sunmaktayız. Avrupa’nın her noktasına, bölgelere göre 24/72 saatlık transit süreler ile servis sunmaktayız.</p>
                                        </div>
                                        <h6>Tüm araçlarımızda GPS sistemi bulunmakta olup, günlük sabah ve öğleden sonra olmak kaydi ile müşterilerimize 2 kez konum göndermekteyiz.</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h3 className="h3-md fw-7 mb-4 txt-blue">Çalışmakta olduğumuz hatlar / Ülkeler</h3>
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li><i className="icofont-check"></i>Almanya</li>
                                        <li><i className="icofont-check"></i>Hollanda</li>
                                        <li><i className="icofont-check"></i>Belçika</li>
                                        <li><i className="icofont-check"></i>Bulgaristan</li>
                                        <li><i className="icofont-check"></i>Avusturya</li>
                                        <li><i className="icofont-check"></i>Çekya</li>
                                        <li><i className="icofont-check"></i>Slovakya</li>
                                        <li><i className="icofont-check"></i>Macaristan</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <img src="images/blog_img_4.jpg" alt="" className="rounded mb-4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    );
};
export default ServiceDetails;
