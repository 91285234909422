import React from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80 contact-full-shadow">
                    <div className="container">
                        <div className="contact-map-bg">
                            <img src="images/map-bg.png" alt="" />
                        </div>
                        <div className="address-container">
                            <div className="wow fadeInRight" data-wow-duration="0" data-wow-delay="0s">
                                <div className="contact-detail-shadow address-item">
                                    <h4>Bulgaria</h4>
                                    <div className="d-flex align-items-start items">
                                        <span>Toker OOOD</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-google-map"></i> <span>Trakya Nova, 32 Osvobozhdenie Blvd 6th floor office 21-22<br/>4023 Plovdiv Bulgaria
</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-phone"></i> <span>+359 32 53 99 88</span>
                                    </div>
                                    <div className="text-nowrap d-flex align-items-start items">
                                        {/* <i className="icofont-email"></i> <a href="#">bulgaria@toker.bg</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="addres-item wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <div className="office-image">

                                </div>
                            </div>
                            <div className="addres-item wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                <div className="contact-detail-shadow address-item">
                                    <h4>Turkey</h4>
                                    <div className="d-flex align-items-start items">
                                        <span>TOKER LTD</span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-google-map"></i> <span>Toker Konak Kandilli Mh Kandilli Cd No:3 <br/>Kandilli Üsküdar </span>
                                    </div>
                                    <div className="d-flex align-items-start items">
                                        <i className="icofont-phone"></i> <span>+90 216 332 83 03</span>
                                    </div>
                                    <div className="text-nowrap d-flex align-items-start items">
                                        {/* <i className="icofont-email"></i> <a href="#">turkey@toker.bg</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 bg-light-gray pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-8 offset-lg-2 wow fadeInUp" data-wow-duration="0" data-wow-delay="0s">
                                <div className="free-quote-form contact-page">
                                    <h1 className="heading-main mb-4">Bize Ulaşın</h1>
                                    <form action="#" method="post" id="contactusForm" noValidate="novalidate" className="col rounded-field">
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <input type="text" name="name" id="name" className="form-control" placeholder="Ad Soyad" />
                                            </div>
                                            <div className="col">
                                                <input type="text" name="email" id="email" className="form-control" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <select title="Please choose a package" required="" name="Transport_Package" id="Transport_Package" className="custom-select" aria-required="true" aria-invalid="false">
                                                    <option value="">Ulaştırma Şekli</option>
                                                    <option value="Type 1">Karayolu</option>
                                                    <option value="Type 2">Denizyolu</option>
                                                    <option value="Type 3">Havayolu</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <textarea rows="7" name="cment" id="cment" placeholder="Mesajınız" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-row text-center">
                                            <button name="contactForm" id="contactForm" type="button" className="form-btn mx-auto btn-theme bg-orange">Gönder<i className="icofont-rounded-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="map-bg">
                    <div id="map-holder" className="pos-rel">
                        <div id="map_extended">
                            <p>This will be replaced with the Google Map.</p>
                        </div>
                    </div>
                </section> */}
            </main>
        </>
    );
};
export default Content;
