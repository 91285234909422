import React from "react";
import Header from "../PagesHeader";
import Footer from "../Footer";
import Content from "./Content";
import Breadcrumbs from "../Breadcrumbs";
import BreadcrumbsData from "../breadcrumbs.json";


const Informations = () => {
	return (
		
		<>
			<Header />
			<Breadcrumbs title={BreadcrumbsData.informations.title} description={BreadcrumbsData.informations.description}/>
			<Content />
			<Footer />
		</>
	);
};
export default Informations;
