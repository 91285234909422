import React from "react";

const ServiceDetails = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <p>Genisleyen  uçuş ağıyla ve sistemli kargo operasyon desteği ile tüm dünyaya ihracat ve ithalat sevkiyatlarında IATA uyesi geniş havayolu işbirliklerimiz sayesinde  havayolu taşımacılık hizmeti sunmaktayız.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h3 className="h3-md fw-7 mb-4 txt-blue">Çalışmakta olduğumuz hatlar / Ülkeler</h3>
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li><i className="icofont-check"></i>İthalat / İhracat taşımaları</li>
                                        <li><i className="icofont-check"></i>Dünya çapında güçlü network</li>
                                        <li><i className="icofont-check"></i>Konsolidasyon hizmetleri</li>
                                        <li><i className="icofont-check"></i>Kapıdan kapıya teslimat</li>
                                        <li><i className="icofont-check"></i>Hızlı güvenilir ve ekonomik hizmet</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <img src="images/blog_img_4.jpg" alt="" className="rounded mb-4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    );
};
export default ServiceDetails;
