import React from "react";

const ServiceDetails = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <p>TOKER Lojistik olarak; Müşterilerinin maddi ve manevi zararlara uğramamışı için uygun Sigorta firmaları ile işbirliği yapılarak en uygun sigorta maliyetine destek hizmetleri vermekteyiz.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    );
};
export default ServiceDetails;
