import React from "react";

const ServiceDetails = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                    <div className="container pos-rel">
                        <div className="row align-items-start" id="road">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <div className="text-left">
                                            <h2>Karayolu Taşımacılığı</h2>
                                            <h4 className="h4-md mb-3 txt-blue fw-7">FTL (Komple Tır Yüklemeleri) ve  LTL (Parsiyel Tır Yüklemeleri)</h4>
                                            <p>Müşterilerimize taleplerine yönelik uygun araç tipleri  (tenteli, kapalı kasa, frigo (ısı kontrollü) açık (sal kasa), gabari dışı yükleme)  ile Komple Tır Taşımacılığı hizmetlerini sunmaktayız.</p>
                                            <p>Kuvvetli acenta ağımız ile müşterilerimize taleplerine uygun parsiyel taşımacılık hizmeti de sunmaktayız. </p>
                                        </div>
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">Express Tır Yüklemeleri</h4>
                                            <p>Müşterilerimizin acil teslimat taleplerinde gerek FTL, gerek LTL yüklemlerinizi Express Tır servisi ile transit sürelerde avantaj sağlayarak hizmet sunmaktayız</p>
                                        </div>
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <h4 className="h4-md mb-3 txt-blue fw-7">Express Minivan ve Megavan Yüklemeleri</h4>
                                            <p>Havayolu Taşımacılığına rakip olarak sunulan Minivan servisimiz ile gerek maliyet, gerek transit süre olarak ciddi avantaj sunmaktayız. Avrupa’nın her noktasına, bölgelere göre 24/72 saatlık transit süreler ile servis sunmaktayız.</p>
                                        </div>
                                        <h6>Tüm araçlarımızda GPS sistemi bulunmakta olup, günlük sabah ve öğleden sonra olmak kaydi ile müşterilerimize 2 kez konum göndermekteyiz.</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h3 className="h3-md fw-7 mb-4 txt-blue">Çalışmakta olduğumuz hatlar / Ülkeler</h3>
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li><i className="icofont-check"></i>Almanya</li>
                                        <li><i className="icofont-check"></i>Hollanda</li>
                                        <li><i className="icofont-check"></i>Belçika</li>
                                        <li><i className="icofont-check"></i>Bulgaristan</li>
                                        <li><i className="icofont-check"></i>Avusturya</li>
                                        <li><i className="icofont-check"></i>Çekya</li>
                                        <li><i className="icofont-check"></i>Slovakya</li>
                                        <li><i className="icofont-check"></i>Macaristan</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="row align-items-start" id="air">
                            <div className="col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <h2>Havayolu Taşımacılığı</h2>
                                        <div className="text-left">
                                            <img src="images/blog_single.jpg" alt="" className="rounded mb-4" />
                                            <p>Genisleyen  uçuş ağıyla ve sistemli kargo operasyon desteği ile tüm dünyaya ihracat ve ithalat sevkiyatlarında IATA uyesi geniş havayolu işbirliklerimiz sayesinde  havayolu taşımacılık hizmeti sunmaktayız.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li><i className="icofont-check"></i>İthalat / İhracat taşımaları</li>
                                        <li><i className="icofont-check"></i>Dünya çapında güçlü network</li>
                                        <li><i className="icofont-check"></i>Konsolidasyon hizmetleri</li>
                                        <li><i className="icofont-check"></i>Kapıdan kapıya teslimat</li>
                                        <li><i className="icofont-check"></i>Hızlı güvenilir ve ekonomik hizmet</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <img src="images/blog_img_4.jpg" alt="" className="rounded mb-4" />
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="sea">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>Denizyolu Taşımacılığı</h2>
                                        <p>Uluslararası ticaretin kıtalararası en önemli sevk aracı olan deniz taşımacılığında, müşterilerimizin taleplerine uygun hizmet sunmaktayız.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <h3 className="h3-md fw-7 mb-4 txt-blue">Sunduğumuz bazı hizmetler</h3>
                                <ul className="list-unstyled icons-listing theme-orange mb-0">
                                    <li><i className="icofont-check"></i>On taşıma, vgm, fabrika dolum lokal, varıs lokal, son tasıma dahil. iki taraf gümrükleme ve sigorta harci</li>
                                    <li><i className="icofont-check"></i>Gümrüksel veya operasyonel süreçler</li>
                                    <li><i className="icofont-check"></i>Kapıdan kapıya hizmet</li>
                                    <li><i className="icofont-check"></i>Dünyanın her yerinden her yerine deniz nakliye bağlantılı multimodal çözümler</li>
                                    <li><i className="icofont-check"></i>Dünya genelinde proje taşımacılığı</li>
                                    <li><i className="icofont-check"></i>Çıkış varış ihbarlarının müşterilere email ile otomatik bildirimi</li>
                                    <li><i className="icofont-check"></i>Uluslararası acente ağı</li>
                                </ul>
                            </div>
                            <div className="col-sm-6">
                                <img src="images/blog_img_4.jpg" alt="" className="rounded mb-4" />
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="fair">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>Fuar Lojistiği</h2>
                                        <p>Uluslararasi ticaretin ve global ekonominin en önemli unsurlarindan fuarlar, ozel sergiler, kongreler, toplantılar için deneyimli personeli ile hizmet saglamaktayiz.</p>
                                        <p>Malzemelerin çıkış işlemleri için dağıtım noktasına getirilmesini
                                            Geçici ya da kati ihracat için gerekli gümrük işlemlerinin yapılması ni
                                            Fuar malzemelerinin dünyanın herhangi bir noktasına kara, deniz, hava, demiryolu kombine taşıma yöntemleri ile en optimum şekilde nakledilmesini
                                            Varış ülkesinde geçici ve kati gümrükleme işlemlerinin yapılmasıni
                                            Malzemelerin fuar öncesi varış ülkesinde depolanması ve belirtilen tarihte teslimatı
                                            Fuar alanında gerekli iş gücü ve teknik ekipman sağlamaktayiz
                                            Fuar sonrası malzemelerin stanttan alınması, ıthalat gümrük işlemlerinin yapılmasıni
                                            En uygun dönüş nakliyesinin organize edilerek yurda giriş gümrük işlemlerinin yapılması saglamaktayiz.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="adr">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>TEHLIKELI MADDE TASIMACAILIGI (ADR)</h2>
                                        <p>Cevrenin güvenliği esas alınarak sürekli denetim ve eğitimlerle alinan ADR belgeleri ve ekipmanlarına sahip filomuz ve uzman eğitimli sürücülerimiz ile organik veya inorganik kimyevi madde ve gıda ürünleri lojistiği alanlarında küresel ve yerel pazarda müşterilerimizin  ihtiyaçlarına cevap vermekteyiz.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="combined">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>KOMBİNE TAŞIMACILIK </h2>
                                        <p>Taşımanın çıkış noktasından varış noktasına kadar en az iki taşıma modu ile (karayolu, demiryolu, denizyolu ve havayolu) taşınması saglamaktayiz.
                                            Temel amacimiz  her türlü işletme içi ve işletmeler arası taşımanın bir taşıma zinciri oluşturarak verimli bir şekilde gerçekleştirilmekteyiz.
                                            Kombine taşımacılığın gerçekleşmesi aşamasında teknolojik, organizasyon, fiyat politikası ve hukuki entegrasyon bir bütün olarak ele alınmaktayız.
                                            Taşımacılık işlemleri birbiri ile koordineli olarak sürdürülmekteyiz.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="frigofic">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>FRİGOFİK TAŞIMACILIK</h2>
                                        <p>Toker Lojistik olarak 18 °C ile -26 °C’ye kadar ayarlanabilir soğutucuları bulunan donanımlı araçlarla frigolu taşımacılık hizmeti sunmaktayiz.
                                            Araçların sıcaklık dengesi sağlanarak ürün kalitesinden ödün vermeden hijyen koşullarına uygun bir şekilde taşınması sağlanmaktayiz.
                                            Urünlerin nakliye aracına yüklemeden boşaltmaya, işletmeye aktarmadan son tüketiciye aktarılmasına tüm nakliye sürecinin titizlikle yönetilmekteyiz.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="warehouse">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>DEPOLAMA HİZMETLERİ</h2>
                                        <p>TOKER Lojistik olarak depolama ve Dağıtım alanında uzman kadrosu, yaygın acente ağı, gelişmiş teknolojik altyapısi, deneyimli müşteri hizmetleri birimi ile kapsamlı hizmet sunmaktayız.
                                            Depolama ve Dağıtım Hizmetlerimiz:
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                    <h3 className="h3-md fw-7 mb-4 txt-blue">Depolama ve Dağıtım Hizmetlerimiz:</h3>
                                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                                        <li><i className="icofont-check"></i>Sevkiyat öncesi hazırlık</li>
                                        <li><i className="icofont-check"></i>Dizme</li>
                                        <li><i className="icofont-check"></i>Birleştirme</li>
                                        <li><i className="icofont-check"></i>Paketleme</li>
                                        <li><i className="icofont-check"></i>Paketleme ve sarf malzeme yönetimi</li>
                                        <li><i className="icofont-check"></i>Depo yönetim sistemi</li>
                                        <li><i className="icofont-check"></i>Gümrüklü ve gümrüksüz depolama</li>
                                        <li><i className="icofont-check"></i>İhtiyaçlara özel çözümler</li>
                                        <li><i className="icofont-check"></i>Sıfır stok kaybı</li>
                                        <li><i className="icofont-check"></i>Yedek parça stok yönetimi ve sevkiyatı</li>
                                        <li><i className="icofont-check"></i>Boş kasa ve palet yönetimi</li>
                                        <li><i className="icofont-check"></i>Ürüne özel raf sistemi</li>
                                        <li><i className="icofont-check"></i>Online takip sistemi</li>
                                        <li><i className="icofont-check"></i>Raporlama</li>
                                    </ul>
                                </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="insurance">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>SİGORTA HİZMETİ</h2>
                                        <p>TOKER Lojistik olarak; Müşterilerinin maddi ve manevi zararlara uğramamışı için uygun Sigorta firmaları ile işbirliği yapılarak en uygun sigorta maliyetine destek hizmetleri vermekteyiz.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                        <div className="col-md-12 col-lg-12" id="customs">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="text-left">
                                        <h2>GÜMRÜKLEME HİZMETİ</h2>
                                        <p>TOKER Lojistik olarak müşterilerinin gümrükleme ihtiyaçlarını en iyi şekilde tespit ederek, sahip olduğu kurumsal bilgi birikimi ve müşteri odaklı yaklaşımıyla, en ideal çözüm yollarını geliştirmekteyiz.
Farklı gümrük noktalarında konuşlanmış kendi bünyesindeki dış operasyon kadroları ve evrak akışını en hızlı seviyede gerçekleştirmeyi sağlayan kurye operasyonlarıylal; gümrükleme süreçlerinin en kısa sürede ve sorunsuz şekilde neticelendirilmesini sağlamaktayiz.
</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of section */}
                        <br />
                    </div>
                </section>
            </main >
        </>
    );
};
export default ServiceDetails;
