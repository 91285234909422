import React from "react";

const Footer = () => {
	return (
		<>
			{/* footer start */}
			<footer className="wide-tb-30 bg-light-gray pb-0">
				<div className="container">
					<div className="row">

						<div className="col-lg-12 col-md-6 wow fadeInDown" style={{ display: 'flex', justifyContent: 'center'}} data-wow-duration="0" data-wow-delay="0s">
							<div className="logo-footer">
								<img src="images/logo_footer.svg" alt="" />
							</div>
						</div>
					</div>
				</div>

				<div className="copyright-wrap bg-navy-blue wide-tb-30">
					<div className="container">
						<div className="row text-md-left text-center">
							<div className="col-sm-12 col-md-6 copyright-links">
								<a href="/about">HAKKIMIZDA</a>   <span>|</span>   <a href="/services">HİZMETLERİMİZ</a>   <span>|</span>   <a href="/contact">İLETİŞİM</a>
							</div>
							<div className="col-sm-12 col-md-6 text-md-right text-center">
								Designed by <a href="#" rel="nofollow">Toker Studio</a> © 2023 All Rights Reserved
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
