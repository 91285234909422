import React from "react";
import Header from "../../../PagesHeader";
import Footer from "../../../Footer";
import Content from "./Content";
import Breadcrumbs from "../../../Breadcrumbs";
import BackToTop from "../../../BackToTop";
import BreadcrumbsData from "../../../breadcrumbs.json";

const ServiceDetails = () => {
	return (
		<>
			<Header />
			<Breadcrumbs title={BreadcrumbsData.services.servicedetails.title} description={BreadcrumbsData.services.servicedetails.description}/>
			<Content />
			<Footer />
			<BackToTop />
		</>
	);
};
export default ServiceDetails;
